import React, { useState } from "react"
import * as Styles from "./signature_igor.module.css"

export default function Signature_igor() {
  const [isToggled, setToggled] = useState(true)

  const handleOnClick = () => {
    setToggled(!isToggled)
  }

  return (
    <div
      onClick={handleOnClick}
      className={`${isToggled && `${Styles.play}`} ${Styles.shapeshifterBg} ${
        Styles.shapeshifter
      }`}
    ></div>
  )
}
