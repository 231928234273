import React from "react"
import * as styles from "./hero.module.css"

export default function Hero() {
  return (
    <section
      className={`hero is-link is-fullheight-with-navbar ${styles.heroImg}`}
    >
      <div className="hero-body">
        <div className="container">
          <p className={styles.heroTitle}>
            Fat & <br /> Furious
          </p>
          <p className={styles.heroSubTitle}>
            Progressons ensemble <br />
            <a
              className="button is-primary is-medium is-rounded"
              href="/register"
            >
              Rejoignez-nous !
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}
