import React from "react"
import SportAventureLogo from "../img/sponsors/sponsor_sport_aventure.png"
import TopScoreDiffusionLogo from "../img/sponsors/sponsor_top_score_diffusion.png"
import Igor from "./signature/signature_igor"

export default function Sponsors() {
  return (
    <div className="section">
      <div className="container">
        <h1 className="title is-1 has-text-centered">{"Fat and Furious"}</h1>
        <h2 className="subtitle has-text-centered">Progressons ensemble !</h2>
        <div className="has-text-centered">
          <a
            className="button is-primary is-medium is-rounded"
            href="/register"
          >
            Rejoignez-nous !
          </a>
        </div>

        <br />
        <h2 className="subtitle has-text-centered">
          Toute l'équipe de la Team Kangourou est très reconnaissante envers ses
        </h2>
        <h1 className="title is-1 has-text-centered">Sponsors</h1>
        <nav className="level">
          <div className="level-item has-text-centered">
            <div></div>
          </div>
          <div className="level-item has-text-centered">
            <figure className="image is-128x128">
              <a href="https://www.sports-aventures.ch/">
                <img src={SportAventureLogo} alt="Sport Aventure Logo" />
              </a>
            </figure>
          </div>
          <div className="level-item has-text-centered">
            <figure className="image is-128x128">
              <a href="http://shop.topscorediffusion.ch/welcome">
                <img
                  src={TopScoreDiffusionLogo}
                  alt="Top Score Diffusion Logo"
                />
              </a>
            </figure>
          </div>
          <div className="level-item has-text-centered">
            <div></div>
          </div>
        </nav>
        <h2 className="subtitle has-text-centered">
          La Team Kangourou remercie également les personnes suivantes pour leur
          contribution envers le club :
        </h2>
        <div className="has-text-centered">
          <Igor />
          <p>
            Igor Francey <br /> pour l'élaboration du logo
          </p>
        </div>
      </div>
    </div>
  )
}
