import React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/hero/hero"
import Sponsor from "../components/sponsors"
import { Helmet } from "react-helmet"
import "./mystyles.scss"

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team Kangourou</title>
        <meta
          name="description"
          content="Team-Kangourou est un club de course sur Fribourg"
        />
        <meta
          name="keywords"
          content="Team, Kangourou, Team Kangourou, Course, Fribourg"
        />
      </Helmet>
      <Layout>
        <Hero />
        <Sponsor />
      </Layout>
    </>
  )
}
